<template>
  <div id="equipmentEdit">
    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer equipment-mro-edit-drawer"
      v-model="flag"
      width="700"
    >
      <div class="ivu-drawer-header-inner" slot="header">
        <span>{{id > 0 ? "详情" : "新增"}}</span>
        <Divider type="vertical" v-if="!ABLE"></Divider>
        <span class="text-sub" v-if="!ABLE">{{$designConst.equipmentMro.Status[formd.Status]}}</span>
      </div>
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form
          :label-width="130"
          :model="formd"
          :rules="rules"
          @submit.native.prevent
          inline
          ref="form"
        >
          <FormItem label="状态：" prop="Status" required v-show="ABLE">
            <Select v-if="ABLE" v-model="formd.Status">
              <Option
                :key="item.key"
                :value="item.key"
                v-for="item in $designConst.equipmentMro.StatusLst"
              >{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="类型：" prop="BillType" required>
            <Select v-if="ABLE" v-model="formd.BillType">
              <Option :value="1">维修单</Option>
              <Option :value="2">维护单</Option>
            </Select>
            <div v-else>{{["","维修单","维护单"][formd.BillType]}}</div>
          </FormItem>
          <FormItem label="单号：" prop="Code" required>
            <Input v-if="ABLE" v-model="formd.Code" />
            <div v-else>{{formd.Code}}</div>
          </FormItem>
          <FormItem label="单据日期：">
            <DatePicker v-if="ABLE" v-model="formd.BillDate" />
            <div v-else>{{formd.BillDate}}</div>
          </FormItem>
          <FormItem class="long" label="仪器：" prop="EquipmentId" required>
            <!-- <Select v-if="ABLE" v-model="formd.EquipmentId">
              <Option
                :key="item.Id"
                :value="item.Id"
                v-for="item in equipment"
              >{{ item.Name }}({{item.LabName}})</Option>
            </Select>-->
            <Input
              :value="formd.EquipmentName?`${formd.EquipmentName||'-'}/${formd.LabName||'-'}/${formd.ModeNo||'-'}/${formd.EquipmentCode||'-'}`:''"
              @on-clear="unPickEquipment"
              clearable
              placeholder="仪器名称/实验室/规格型号/仪器编号"
              readonly
              v-if="ABLE"
            >
              <Button @click="toPickEquipment" icon="ios-more" slot="append"></Button>
            </Input>
            <div v-else>{{formd.EquipmentName}}</div>
          </FormItem>
          <FormItem label="跟单人：">
            <Input
              :value="formd.PrincipalName"
              @on-clear="unPickUser"
              clearable
              readonly
              v-if="ABLE"
            >
              <Button @click="toPickUser" icon="ios-more" slot="append"></Button>
            </Input>
            <div v-else>{{formd.PrincipalName}}</div>
          </FormItem>
          <FormItem label="跟单人电话：">
            <Input clearable v-if="ABLE" v-model="formd.PrincipalTel" />
            <div v-else>{{formd.PrincipalTel}}</div>
          </FormItem>
          <FormItem label="设备方联系人：">
            <Input v-if="ABLE" v-model="formd.Contact" />
            <div v-else>{{formd.Contact}}</div>
          </FormItem>
          <FormItem label="设备方联系电话：">
            <Input v-if="ABLE" v-model="formd.ContactTel" />
            <div v-else>{{formd.ContactTel}}</div>
          </FormItem>
          <FormItem class="long" label="维修说明：">
            <Input type="textarea" v-if="ABLE" v-model="formd.Description" />
            <div v-else>{{formd.Description}}</div>
          </FormItem>
          <FormItem class="long" label="维修结果：">
            <Input type="textarea" v-if="ABLE" v-model="formd.Result" />
            <div v-else>{{formd.Result}}</div>
          </FormItem>
          <FormItem class="long" label="备注：">
            <Input type="textarea" v-if="ABLE" v-model="formd.Remark" />
            <div v-else>{{formd.Remark}}</div>
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer" v-if="ABLE">
        <Button :loading="posting" @click="onOk" type="primary">保存</Button>
      </div>
    </Drawer>
    <pick-user @on-ok="onPickUser" ref="PickUser" type="1" />
    <pick-equipment @on-ok="onPickEquipment" ref="PickEquipment" />
  </div>
</template>
<script>
import PickUser from "../../components/PickUser.vue";
import PickEquipment from "../../components/PickEquipment.vue";
export default {
  components: { PickUser, PickEquipment },
  data() {
    return {
      ABLE: false,
      flag: false,
      id: 0,
      formd: {},
      rules: {
        Status: [{ required: true, type: "number", message: " " }],
        Code: [{ required: true, message: " " }],
        BillType: [{ required: true, type: "number", message: " " }],
        EquipmentId: [{ required: true, type: "number", message: " " }],
      },
      loading: false,
      failed: false,
      posting: false,
      equipment: [],
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      if (id) {
        this.ABLE = this.$able.map.EquMroUpd;
        this.id = id;
        this.req();
      } else {
        this.formd = {
          EquipmentName: "",
          LabName: "",
          ModeNo: "",
          EquipmentCode: "",
        };
        this.ABLE = this.$able.map.EquMroAdd;
      }
      this.reqEquipment();
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsEquipmentMro/GetVo/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    close() {
      this.ABLE = false;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
      this.id = 0;
    },
    onOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      let api =
        this.id > 0
          ? "/Api/BtmsEquipmentMro/Update"
          : "/Api/BtmsEquipmentMro/Add";
      let param = this.$util.copy(this.formd);
      if (
        this.formd.BillDate != null &&
        typeof this.formd.BillDate == "object"
      ) {
        param.BillDate = this.formd.BillDate.format("yyyy-MM-dd");
      }
      this.$axios
        .post(api, param)
        .then((res) => {
          if (res.result) {
            this.$Message.success(this.id > 0 ? "编辑成功" : "新增成功");
            this.$emit(this.id > 0 ? "on-upd" : "on-add");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
          this.posting = false;
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // ==========
    reqEquipment() {
      this.$axios
        .get("/Api/BtmsEquipment/QueryPg", { params: { PageSize: 99 } })
        .then((res) => {
          if (res.result) {
            this.equipment = res.data.DataSet;
          }
        });
    },
    // 选择人员
    toPickUser() {
      let def = [];
      if (this.formd.PrincipalId) {
        def = [
          {
            Id: this.formd.PrincipalId,
            UserName: this.formd.PrincipalName,
          },
        ];
      }
      this.$refs.PickUser.open(def);
    },
    onPickUser(lst) {
      if (lst[0]) {
        this.formd.PrincipalId = lst[0].Id;
        this.$set(this.formd, "PrincipalName", lst[0].UserName);
        this.$set(this.formd, "PrincipalTel", lst[0].Mobile);
      } else {
        this.formd.PrincipalId = "";
        this.$set(this.formd, "PrincipalName", "");
      }
    },
    unPickUser() {
      this.formd.PrincipalId = "";
      this.$set(this.formd, "PrincipalName", "");
    },
    // 选择仪器
    toPickEquipment() {
      let def = [];
      if (this.formd.EquipmentId) {
        def = [
          {
            Id: this.formd.EquipmentId,
            UserName: this.formd.EquipmentName,
          },
        ];
      }
      this.$refs.PickEquipment.open(def);
    },
    onPickEquipment(lst) {
      if (lst[0]) {
        this.formd.EquipmentId = lst[0].Id;
        this.$set(this.formd, "EquipmentName", lst[0].Name);
        this.$set(this.formd, "LabName", lst[0].LabName);
        this.$set(this.formd, "ModeNo", lst[0].ModeNo);
        this.$set(this.formd, "EquipmentCode", lst[0].Code);
      } else {
        this.formd.EquipmentId = "";
        this.$set(this.formd, "EquipmentName", "");
        this.$set(this.formd, "LabName", "");
        this.$set(this.formd, "ModeNo", "");
        this.$set(this.formd, "EquipmentCode", "");
      }
    },
    unPickEquipment() {
      this.formd.EquipmentId = "";
      this.$set(this.formd, "EquipmentName", "");
      this.$set(this.formd, "EquipmentName", "");
      this.$set(this.formd, "LabName", "");
      this.$set(this.formd, "ModeNo", "");
      this.$set(this.formd, "EquipmentCode", "");
    },
  },
};
</script>
<style lang="less">
.equipment-mro-edit-drawer {
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>