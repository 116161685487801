<template>
  <Drawer
    :mask-closable="false"
    :title="id==0?'新增记录':'编辑记录'"
    :transfer="false"
    @on-close="close"
    class="bk-sub-drawer mro-trace-edit-drawer"
    v-model="flag"
    width="401"
  >
    <div class="bk-drawer-body">
      <Form :label-width="120" :model="formd" :rules="rules" @submit.native.prevent ref="form">
        <FormItem label="状态：" prop="Status" required>
          <Select v-if="ABLE" v-model="formd.Status">
            <Option
              :key="item.key"
              :value="item.key"
              v-for="item in $designConst.equipmentMroTrace.StatusLst"
            >{{ item.name }}</Option>
          </Select>
          <div v-else>{{$designConst.equipmentMroTrace.Status[formd.Status]}}</div>
        </FormItem>
        <FormItem label="维护时间：">
          <DatePicker
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            v-if="ABLE"
            v-model="formd.ServicingTime"
          />
          <div v-else>{{formd.ServicingTime}}</div>
        </FormItem>
        <FormItem label="维修人员：">
          <Input v-if="ABLE" v-model="formd.Serviceman" />
          <div v-else>{{formd.Serviceman}}</div>
        </FormItem>
        <FormItem label="维修人员电话：">
          <Input v-if="ABLE" v-model="formd.Tel" />
          <div v-else>{{formd.Tel}}</div>
        </FormItem>
        <FormItem label="验收时间：">
          <DatePicker
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            v-if="ABLE"
            v-model="formd.AcceptanceDate"
          />
          <div v-else>{{formd.AcceptanceDate}}</div>
        </FormItem>
        <FormItem class="long" label="处理结果描述：">
          <Input type="textarea" v-if="ABLE" v-model="formd.Description" />
          <div v-else>{{formd.Description}}</div>
        </FormItem>
        <FormItem class="long" label="备注：">
          <Input type="textarea" v-if="ABLE" v-model="formd.Remark" />
          <div v-else>{{formd.Remark}}</div>
        </FormItem>
      </Form>
    </div>
    <div class="bk-drawer-footer" v-if="ABLE">
      <Button :loading="posting" @click="ok" type="primary">保存</Button>
    </div>
  </Drawer>
</template>
<script>
export default {
  props: {
    billid: [String, Number],
  },
  data() {
    return {
      ABLE: false,
      id: 0,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: {},
      rules: {
        Status: [{ required: true, type: "number", message: " " }],
      },
    };
  },
  methods: {
    open(id) {
      this.id = id || 0;
      this.formd = {};
      this.$refs.form.resetFields();
      if (this.id > 0) {
        this.ABLE = this.$able.map.EquMroUpd;
        this.req();
      } else {
        this.ABLE = this.$able.map.EquMroAdd;
      }
      this.flag = true;
    },
    close() {
      this.ABLE = false;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.id = 0;
      this.$refs.form.resetFields();
      this.formd = {};
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsEquipmentMroTrace/GetById", { params: { Id: this.id } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    postData() {
      this.posting = true;
      let api =
        this.id > 0
          ? "/Api/BtmsEquipmentMroTrace/Update"
          : "/Api/BtmsEquipmentMroTrace/Add";
      let param = this.$util.copy(this.formd);
      param.BillId = this.billid;
      if (this.formd.ServicingTime != null && typeof this.formd.ServicingTime == "object") {
        param.ServicingTime = this.formd.ServicingTime.format(
          "yyyy-MM-dd hh:mm:ss"
        );
      }
      if (this.formd.AcceptanceDate != null && typeof this.formd.AcceptanceDate == "object") {
        param.AcceptanceDate = this.formd.AcceptanceDate.format(
          "yyyy-MM-dd hh:mm:ss"
        );
      }
      this.$axios
        .post(api, param)
        .then((res) => {
          if (res.result) {
            this.$Message.success(this.id > 0 ? "编辑成功" : "新增成功");
            this.$emit(this.id > 0 ? "on-upd" : "on-add");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
          this.posting = false;
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less">
.mro-trace-edit-drawer {
  .ivu-drawer-mask {
    background-color: transparent;
    right: 500px;
  }
  .ivu-drawer-wrap {
    right: 500px;
    border-right: 1px solid #dcdee2;
  }
  // .ivu-form-item {
  //   width: 50%;
  //   margin-right: 0;
  // }
  // .ivu-form-item.long {
  //   width: 100%;
  // }
}
</style>

