<template>
  <div class="bk-page">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form :label-width="80" @submit.native.prevent class="bk-page-search" inline>
          <FormItem label="状态：">
            <Select v-model="searchData1.Status">
              <Option
                :key="item.key"
                :value="item.key"
                v-for="item in $designConst.equipmentMro.StatusLst"
              >{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="实验室">
            <Select clearable v-model="searchData1.LabId">
              <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{item.Name}}</Option>
            </Select>
          </FormItem>
          <FormItem class="btns" style="width:220px">
            <Button @click="onSearch" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
            <Button @click="toAdd" ghost type="primary" v-if="$able.map.EquMroAdd">
              <Icon type="md-add" />新增
            </Button>
          </FormItem>
        </Form>
        <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table>
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          transfer
        />
      </div>
    </div>
    <edit @on-add="onChangePage(1)" @on-upd="reqTable" ref="edit" />
    <trace ref="trace" />
  </div>
</template>
<script>
import edit from "./mro-edit";
import trace from "./mro-trace";
import SelectLabId from "@/components/select-labid.js";
export default {
  components: { edit, trace },
  mixins: [SelectLabId],
  data() {
    return {
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableCols: [
        {
          title: "状态",
          key: "Status",
          width: 100,
          render: (h, params) => {
            return h("Badge", {
              props: {
                status:
                  this.$designConst.equipmentMro.StatusBadge[params.row.Status],
                text: this.$designConst.equipmentMro.Status[params.row.Status],
              },
            });
          },
        },
        { title: "实验室", key: "LabName", width: 80 },
        {
          title: "仪器",
          minWidth: 150,
          render: (h, params) => {
            return [
              h(
                "span",
                {
                  style: {marginRight: "5px"},
                },
                params.row.EquipmentName
              ),
              h(
                "span",
                {
                  attrs: { class: "text-sub text-xs" },
                  style: { whiteSpace: "nowrap" },
                },
                params.row.LabName
              ),
              h(
                "div",
                {
                  attrs: { class: "text-sub text-xs" },
                },
                params.row.ModeNo
              ),
              h(
                "div",
                {
                  attrs: { class: "text-sub text-xs" },
                },
                params.row.EquipmentCode
              ),
            ];
          },
        },
        { title: "单号", key: "Code", minWidth: 100 },
        {
          title: "类型",
          width: 80,
          render: (h, params) =>
            h("span", params.row.BillType == 1 ? "维修单" : "维护单"),
        },
        {
          title: "单据日期",
          width: 100,
          render: (h, params) => {
            return h(
              "span",
              params.row.BillDate ? params.row.BillDate.substring(0, 10) : "--"
            );
          },
        },
        {
          title: "跟单人",
          minWidth: 100,
          render: (h, params) => {
            return [
              h(
                "span",
                {
                  style: {marginRight: "5px"},
                },
                params.row.PrincipalName
              ),
              h(
                "span",
                {
                  attrs: { class: "text-sub text-xs" },
                  style: { whiteSpace: "nowrap" },
                },
                params.row.PrincipalTel
              ),
            ];
          },
        },
        {
          title: "设备方",
          minWidth: 100,
          render: (h, params) => {
            return [
              h(
                "span",
                {
                  style: {marginRight: "5px"},
                },
                params.row.Contact
              ),
              h(
                "span",
                {
                  attrs: { class: "text-sub text-xs" },
                  style: { whiteSpace: "nowrap" },
                },
                params.row.ContactTel
              ),
            ];
          },
        },
        {
          title: "操作",
          width: 150,
          align: "right",
          render: (h, params) => {
            let a = [];
            a.push(
              h(
                "span",
                {
                  style: {
                    cursor: "pointer",
                    color: "#2d8cf0",
                  },
                  on: {
                    click: () => {
                      this.$refs.trace.open(params.row.Id);
                    },
                  },
                },
                "维修记录"
              )
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            a.push(
              h("Icon", {
                props: {
                  type: this.$able.map.EquMroUpd ? "md-create" : "md-open",
                  size: 18,
                },
                attrs: { title: this.$able.map.EquMroUpd ? "编辑" : "详情" },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    this.$refs.edit.open(params.row.Id);
                  },
                },
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            if (this.$able.map.EquMroDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    console.info("激活：", this.$tab.conf[this.$route.name].label);
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$refs.edit.flag) {
        this.$refs.edit.close();
      }
      if (this.$refs.trace.flag) {
        this.$refs.trace.close();
      }
      this.initSearch();
      this.reqLab();
    },
    initSearch() {
      this.searchData1 = {};
      this.pageIndex = 1;
      this.onSearch();
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    reqTable() {
      if (this.$able.map.EquMroQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/BtmsEquipmentMro/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            this.tableData = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // ==========
    toAdd() {
      this.$refs.edit.open();
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Code),
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsEquipmentMro/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>

