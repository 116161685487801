<template>
  <div>
    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer mro-trace-drawer"
      title="维修记录"
      v-model="flag"
      width="500"
    >
      <Button @click="toAdd" ghost size="small" type="primary" v-if="$able.map.EquMroAdd">新增记录</Button>
      <Table :columns="cols" :data="lst" :loading="loading"></Table>
      <Page
        :current="pi"
        :page-size="ps"
        :total="all"
        @on-change="(index) => onChangePage(index, 0)"
        @on-page-size-change="(size) => onChangePage(1, size)"
        style="flex: 1; text-align: left; line-height: 32px"
      />
    </Drawer>
    <edit :billid="id" @on-add="onChangePage(1)" @on-upd="reqLst" ref="edit" />
  </div>
</template>
<script>
import edit from "./mro-trace-edit";
export default {
  components: { edit },
  data() {
    return {
      id: 0,
      flag: false,
      loading: false,
      pi: 1,
      ps: 10,
      all: 0,
      lst: [],
      cols: [
        {
          title: "状态",
          key: "Status",
          width: 100,
          render: (h, params) => {
            return h("Badge", {
              props: {
                status:
                  this.$designConst.equipmentMroTrace.StatusBadge[
                    params.row.Status
                  ],
                text: this.$designConst.equipmentMroTrace.Status[
                  params.row.Status
                ],
              },
            });
          },
        },
        {
          title: "维护时间",
          render: (h, params) =>
            h(
              "span",
              params.row.ServicingTime
                ? params.row.ServicingTime.substring(0, 16)
                : ""
            ),
        },
        {
          title: "维修人员",
          width: 110,
          render: (h, params) => {
            return [
              h("div", params.row.Serviceman),
              h(
                "span",
                {
                  attrs: { class: "text-sub text-xs" },
                },
                params.row.Tel
              ),
            ];
          },
        },
        {
          title: "操作",
          width: 90,
          align: "right",
          render: (h, params) => {
            let a = [];
            a.push(
              h("Icon", {
                props: {
                  type: this.$able.map.EquMroUpd ? "md-create" : "md-open",
                  size: 18,
                },
                attrs: { title: this.$able.map.EquMroUpd ? "编辑" : "详情" },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    this.$refs.edit.open(params.row.Id);
                  },
                },
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            if (this.$able.map.EquMroDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      this.id = id;
      this.reqLst();
    },
    close() {
      this.$refs.edit.close();
      this.flag = false;
      this.loading = false;
      this.id = 0;
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pi = index;
      }
      if (size > 0) {
        this.ps = size;
      }
      this.reqLst();
    },
    reqLst() {
      this.loading = true;
      let params = {
        PageIndex: this.pi,
        PageSize: this.ps,
        BillId: this.id
      };
      this.$axios
        .get("/Api/BtmsEquipmentMroTrace/QueryPage", { params: params })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.all = res.data.RecordCount;
            this.lst = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toAdd() {
      this.$refs.edit.open();
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除",
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsEquipmentMroTrace/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less">
.mro-trace-drawer {
  .ivu-table-wrapper {
    border: 1px solid #e8eaec;
    margin: 20px 0 10px;
  }
  .ivu-table:before {
    display: none;
  }
  .ivu-table-overflowX {
    overflow-x: unset;
  }
  .ivu-table-row:last-child td {
    border-bottom: 0;
  }
}
</style>

